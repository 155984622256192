import FrontendConfig from "../Models/FrontendConfig";

class Config {
    public shipment_cost_base = 21;
    public physical_price = 78;
    public audio_price = 999;
    public epub_price = 37;
    public pdf_price = 37;

    public shipment_cost = 21;
    public physical_discounted_amount = 78;
    public audio_discounted_amount = 999;
    public epub_discounted_amount = 37;
    public pdf_discounted_amount = 37;

    public max_physical = 5;

    public phone_number = { local: "0527208555", international: "972527208555" };

    private addressBaseAPI = "https://data.gov.il/api/3/action/datastore_search?resource_id";
    public citiesAPI = `${this.addressBaseAPI}=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=1500`;
    public streetsAPI = `${this.addressBaseAPI}=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=10000`;

    private backendAPI = "https://api.demonsandangels.co.il/api/v1";
    public ordersEndpoint = `${this.backendAPI}/orders`;
    public authEndpoint = `${this.backendAPI}/auth`;
    public contactEndpoint = `${this.backendAPI}/contact`;
    public couponsEndpoint = `${this.backendAPI}/coupons`;
    public configEndpoint = `${this.backendAPI}/config`;

    public showBanner = true;
    public banner_message = "";

    public env: string = process.env.NODE_ENV as string | "production";

    public updateConfig(c: FrontendConfig) {
        this.epub_discounted_amount = c.epub_discounted_amount;
        this.epub_price = c.epub_price;
        this.max_physical = c.max_physical;
        this.phone_number = { local: c.local_phone, international: c.international_phone };
        this.physical_discounted_amount = c.physical_discounted_amount;
        this.physical_price = c.physical_price;
        this.shipment_cost = c.shipment_cost;
        this.shipment_cost_base = this.shipment_cost_base;
        this.showBanner = c.showBanner;
        this.banner_message = c.banner_message;
    }
}
const config = new Config();

export default config;
